@import 'variables';
@import 'mixin';
@import 'components';
@import 'utilities';
@import 'fonts';
@import 'nav';
@import 'table';
@import 'fix-grid';
@import './login.scss';
@import '../modules/admin/accommodation/form/form';
@import '../modules/admin/participant/particpant';
@import '../modules/admin/app/dashboard/components/general';
@import '../modules/admin/app/dashboard/components/satisfaction';
@import '../modules/admin/activity/index/index';
@import '../modules/admin/activity/statistics/statistics';
@import '../modules/admin/eventQuestion/list/list';
@import '../modules/admin/eventHotel/index/rooms/rooms';
@import '../modules/admin/eventAirTravel/form/formTicket/_formTicket';
@import '../modules/public/pages/dashboard/dashboard';
@import '../modules/public/pages/certificate/certificate';
@import '../modules/public/pages/credential/credential';
@import '../modules/public/pages/voucher/voucher';
@import '../modules/public/pages/findRetailer/findRetailer.scss';

body {
  background: $background-color;
  color: $color;
  fill: $color;
  box-sizing: border-box;
}

.flex-box {
  display: flex;
  flex-wrap: wrap;
}

.box-line {
  width: 100%;
}

.check-box-width {
  width: 30%;
}

.onlyprint {
  display: none;
}

[ng-click] {
  cursor: pointer;
}

md-content.main {
  background: #efefef;
}

md-tabs.no-header > md-tabs-wrapper {
  display: none;
}

.logo {
  text-align: center;
  display: block;

  h1 {
    color: #000;
    text-align: center;
    text-shadow: 0 0 4px rgba(255, 255, 255, 0.7);
  }

  img {
    max-width: 180px;
    max-height: 80px;
    margin-top: 60px;
    margin-bottom: 40px;
  }
}

.layout-padding{
  padding:0px !important;
}

.user-info p {
  display: block;
  box-sizing: border-box;
  font-size: 16px;
  width: 100%;
  padding: 0 16px;
  margin-bottom: 10px;
}

.main-content {
  display: block;
  background: none;
  height: 100%;

  padding:0 !important;

  @include media-max-breakpoints(xs) {
    margin: -5px;
    padding: 0 !important;
  }
}

md-toolbar {
  &:before {
    top: 0;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 -1px 8px rgba(0, 0, 0, 0.28);
    content: '';
    height: 100%;
    left: 0;
    right: 0;
    position: absolute;
    will-change: top, left;
  }

  img {
    max-height: 80%;
    margin: auto;
  }
}

.error-message {
  color: $danger;
  margin-bottom: 20px;
}

md-input-container .md-resize-wrapper {
  width: 100%;
}

.abf-search-toolbar {
  input {
    color: #fff;
  }
}

md-toolbar.md-hue-1:not(.md-menu-toolbar) md-input-container {
  label {
    color: white;
  }
}

.md-dialog-content > p:first-child {
  margin-top: 0;
}

.md-toast-success .md-toast-content {
  background: #29a262 !important;
}

.md-toast-error .md-toast-content {
  background: #c62828 !important;
}

.abrafarma-sm-menu {
  @include media-min(1100px) {
    display: none;
  }
}

.abrafarma-lg-menu {
  display: none;

  @include media-min(1100px) {
    display: block;
  }
}

.abrafarma-logout-button {
  margin: auto auto 5px;
  width: 96%;
}

.dashboard-table-featured td {
  background: darken($background-color, 5%);
  font-size: 16px !important;
}

.margintop {
  margin-top: 25px;
}

.margin-right {
  margin-right: 23px !important;
}

.marginleft {
  p {
    margin-left: -40px;
  }

  md-radio-group {
    margin-left: -25px;
  }
}

.status-icon-premium {
  fill: $success;
}

.user-sender-toolbar {
  background-color: #fff !important;
  color: rgb(18, 88, 138) !important;
}

.icon-toolbar-user-sender {
  fill: rgb(18, 88, 138) !important;
}

.activity-grey {
  background: #efefef;
  opacity: 0.7;
}

.provider-checklist-item-open {
  background-color: #fff !important;
  color: #c62828 !important;
}

.provider-checklist-item-closed {
  background-color: #fff !important;
  color: #29a262 !important;
}

img.officialProvider-logo-small {
  max-height: 120px;
  max-width: 250px;
  margin: 10px 0;
  margin-left: 15px;
}

.mr-5 {
  margin-right: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mr-20 {
  margin-right: 20px;
}

.titleFindRetailer {
  color: rgb(11, 134, 200) !important;
}

.titleActivityClub {
  justify-content: space-between;
}

.titleActivity {
  color: #777777;
  font-weight: bold;
}

.colorGray {
  color: gray;
}

.colorGreen {
  color: green;
  font-weight: bolder;
}

.colorGreenLight {
  color: green;
  font-weight: lighter;
}

.max-70 {
  max-width: 70% !important;
}

.max-30 {
  max-width: 30% !important;
}

@media print {
  @page {
    margin: 0;
    box-shadow: none !important;
  }

  md-content,
  body,
  html {
    background: white;
  }

  md-card {
    box-shadow: none !important;
  }

  .noprint {
    display: none !important;
  }

  .onlyprint {
    display: block;
    border: none !important;
  }

  html {
    max-width: 900px !important;
    width: 900px !important;
    min-width: 900px;
  }

  body {
    max-width: 900px !important;
    width: 900px !important;
    min-width: 900px;
  }
}

li {
  list-style-type: none;
}

.eventTitle {
  font-weight: bolder;
  text-decoration: underline;
}

.upper {
  text-transform: uppercase;
}

.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.w-100 {
  width: 100%;
}
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}

.new-logo {
  width: 95%;
  max-width: 100px;
  height: 10px;
  overflow: hidden;
  margin: 20px auto 20px auto;
}

.participant-dialog-import {
  table.md-table {
    min-width: 100%;
    width: auto;
    table-layout: fixed;
    td.md-cell:nth-child(n+2):nth-last-child(n+2),
    th.md-column:nth-child(n+2):nth-last-child(n+2),
    td.md-cell:first-child,
    th.md-column:first-child,
    td.md-cell:last-child,
    th.md-column:last-child {
      padding: 8px !important;
      white-space: nowrap;
      font-size: 12px;
      letter-spacing: 0.5px;
    }
  }
  @include media-max(959px) {
    md-table-container {
      min-height: calc(100vh - 170px);
    }
    md-dialog-content {
      min-height: calc(100vh - 170px) !important;
    }
  }
  md-dialog-actions {
    padding: 0 !important;
    box-sizing: border-box;
    md-table-pagination {
      padding: 0;
      height: 40px;
    }
    .buttons {
      padding: 10px 16px;
    }
  }
}

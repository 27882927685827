.activity-page {
  .activity-grey {
    background: #efefef;
    opacity: 0.7;
  }

  .md-body-2 md-icon,
  .md-subhead md-icon {
    margin-right: 5px;
    vertical-align: middle;
  }

  .md-table tr:first-child td {
    border: none;
  }
}

.images-activity {
  width: 150px;
  height: 150px;
  margin: auto;
}

.public-question {
  margin-top: 20px !important;
  margin-left: 60px !important;
}

md-toolbar:not(.md-menu-toolbar) {
  background-color: #073567;
}

.md-caption{
  color:#7ec32c;
}

md-sidenav .md-button.md-primary:hover{
  color:#7ec32c;
  background:#0b86c8;
  font-weight:600;
}

md-sidenav {
  background-color:  #073567;
  transition: all 0.2s ease-in;
  min-width: 200px;
  max-width: 220px;
  max-height: 100%;
  overflow: auto;
  
  .margintop {
    margin-top: -30px;
  }

  .md-button {
    display: block;
    text-align: left;
    text-transform: none;
    text-overflow: ellipsis;
    
    &.md-primary {
      color: white;
      font-weight:400;
      &:not([disabled]) md-icon{
        color:white;
      }
    }

    md-icon {
      margin-right: 5px;
    }
  }
}

.event-hotel-index-room-component {
  .accommodation-person-less-age td:not(.md-icon-cell) {
    opacity: 0.6;
  }

  .accommodation-title {
    .md-subhead {
      padding-left: 33px;
    }

    md-icon {
      fill: $success;
      margin-right: 7px;
      vertical-align: middle;
    }

    &.accommodation-room-full md-icon {
      fill: $danger;
    }
  }
}

@media print {
  .page-break {
    page-break-after: always;
  }
}

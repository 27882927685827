@import './_variables';
#login {
  overflow: hidden;
  height: 100vh;
  width: calc(100vw + 8px);
  margin: -8px;
  min-height: 650px;
  flex: 1;
  @include media-min-breakpoints(sm) {
    min-height: 0;
    margin: 0;
  }
  .link {
    text-align: right;
    margin-top: 40px;
    a {
      text-decoration: none;
      color: $color;
      &:hover {
        color: $accent;
      }
    }
  }
  #login-v2-intro {

    .main-content{
      padding:0px;
    }
    
    background: url("../img/background.jpg") no-repeat;
    background-size: cover;
    background-size: 100% 100%;
    background-position: center;
    text-align: center;
    position: relative;
    h1 {
      color: white;
      margin: 0;
      font-family: 'Cabourg OT';
      letter-spacing: 1px;
      text-shadow: 1px 1px 8px black;
      max-width: 800px;
    }
    .logos {
      background: white;
      border-radius: 8px;
      box-shadow: 2px 2px 5px black;
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      max-width: 860px;
      >div {
        padding: 10px 10px;
        text-align: center;
      }
      img {
        max-width: 100%;
      }
    }
    @include media-min-breakpoints(sm) {
      text-align: left;
      h1 {
        font-size: 40px;
      }
    }
    @include media-min-breakpoints(md) {
      padding: 40px;
      h1 {
        font-size: 50px;
      }
      .logos {
        bottom: 40px;
        left: 40px;
        right: 40px;
      }
    }
    @include media-min-breakpoints(lg) {
      padding: 60px;
      .logos {
        bottom: 60px;
        left: 60px;
        right: 60px;
      }
    }
  }
  #login-v2-form-wrapper {
    background: #FFFFFF;
    position: relative;
    @include media-min-breakpoints(sm) {
      min-width: 300px;
      max-width: 300px;
    }
    @include media-min-breakpoints(md) {
      min-width: 416px;
      max-width: 416px;
    }
    #login-v2-form {
      padding: 30px;
      @include media-min-breakpoints(sm) {
        padding: 128px 28px 30px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        height: 520px;
      }
      @include media-min-breakpoints(md) {
        padding: 128px 48px 30px;
      }
      .login-img {
        text-align: center;
        img {
          margin-bottom: 20px;
        }
      }
      .logo {
        text-align: center;
        width: 128px;
        height: 128px;
        line-height: 128px;
        font-size: 86px;
        font-weight: 500;
        margin: 32px auto;
        color: #FFFFFF;
        border-radius: 2px;
      }
      .title {
        font-size: 21px;
      }
      .description {
        padding-top: 8px;
      }
      form {
        width: 100%;
        text-align: left;
        padding-top: 32px;
        md-checkbox {
          margin: 0;
        }
      }
      .separator {
        font-size: 15px;
        font-weight: 600;
        margin: 24px auto 16px;
        position: relative;
        overflow: hidden;
        width: 100px;
        text-align: center;
        color: rgba(0, 0, 0, 0.54);
        .text {
          display: inline-block;
          vertical-align: baseline;
          position: relative;
          padding: 0 8px;
          z-index: 9999;
          &:after,
          &:before {
            content: '';
            display: block;
            width: 30px;
            position: absolute;
            top: 10px;
            border-top: 1px solid rgba(0, 0, 0, 0.12);
          }
          &:before {
            right: 100%;
          }
          &:after {
            left: 100%;
          }
        }
      }
      .md-button {
        &.facebook,
        &.google {
          width: 70%;
          text-transform: none;
          color: #FFFFFF;
          font-size: 13px;
          md-icon {
            color: #FFFFFF;
            margin: 0 8px 0 0;
          }
        }
        &.google {
          background-color: #D73D32;
        }
        &.facebook {
          background-color: rgb(63, 92, 154);
        }
      }
      .register {
        margin: 32px auto 24px;
        width: 250px;
        font-weight: 500;
        .text {
          margin-right: 8px;
        }
      }
    }
  }
  .md-button {
    width: 100%;
    margin: 16px auto;
    display: block;
    background-color: #3F51B5;
  }
}


.login-logo-container {
  width: 100%;
  height: 90px;
  overflow: hidden;
  margin: -35px auto 20px auto;
  img.logo-img {
    max-width: 100%;
  }
}

.login-new-logo-container {
  width: 100%;
  max-width: 300px;
  height: 90px;
  overflow: hidden;
  margin: 0 auto 20px auto;
  img.logo-img {
    max-width: 100%;
  }
}

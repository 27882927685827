/*!
 * AngularJS Material Design
 * https://github.com/angular/material
 * @license MIT
 * v1.1.3
 */


/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*
*/


/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*/


/*
 *  Apply Mixins to create Layout/Flexbox styles
 *
 */

.flex-0 {
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}

.layout-row>.flex-0 {
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}

.layout-row>.flex-0 {
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}

.flex-5 {
  -webkit-flex: 1 1 5%;
  flex: 1 1 5%;
}

.layout-row>.flex-5 {
  -webkit-flex: 1 1 5%;
  flex: 1 1 5%;
}

.layout-row>.flex-5 {
  -webkit-flex: 1 1 5%;
  flex: 1 1 5%;
}

.flex-10 {
  -webkit-flex: 1 1 10%;
  flex: 1 1 10%;
}

.layout-row>.flex-10 {
  -webkit-flex: 1 1 10%;
  flex: 1 1 10%;
}

.layout-row>.flex-10 {
  -webkit-flex: 1 1 10%;
  flex: 1 1 10%;
}

.flex-15 {
  -webkit-flex: 1 1 15%;
  flex: 1 1 15%;
}

.layout-row>.flex-15 {
  -webkit-flex: 1 1 15%;
  flex: 1 1 15%;
}

.layout-row>.flex-15 {
  -webkit-flex: 1 1 15%;
  flex: 1 1 15%;
}

.flex-20 {
  -webkit-flex: 1 1 20%;
  flex: 1 1 20%;
}

.layout-row>.flex-20 {
  -webkit-flex: 1 1 20%;
  flex: 1 1 20%;
}

.layout-row>.flex-20 {
  -webkit-flex: 1 1 20%;
  flex: 1 1 20%;
}

.flex-25 {
  -webkit-flex: 1 1 25%;
  flex: 1 1 25%;
}

.layout-row>.flex-25 {
  -webkit-flex: 1 1 25%;
  flex: 1 1 25%;
}

.layout-row>.flex-25 {
  -webkit-flex: 1 1 25%;
  flex: 1 1 25%;
}

.flex-30 {
  -webkit-flex: 1 1 30%;
  flex: 1 1 30%;
}

.layout-row>.flex-30 {
  -webkit-flex: 1 1 30%;
  flex: 1 1 30%;
}

.layout-row>.flex-30 {
  -webkit-flex: 1 1 30%;
  flex: 1 1 30%;
}

.flex-35 {
  -webkit-flex: 1 1 35%;
  flex: 1 1 35%;
}

.layout-row>.flex-35 {
  -webkit-flex: 1 1 35%;
  flex: 1 1 35%;
}

.layout-row>.flex-35 {
  -webkit-flex: 1 1 35%;
  flex: 1 1 35%;
}

.flex-40 {
  -webkit-flex: 1 1 40%;
  flex: 1 1 40%;
}

.layout-row>.flex-40 {
  -webkit-flex: 1 1 40%;
  flex: 1 1 40%;
}

.layout-row>.flex-40 {
  -webkit-flex: 1 1 40%;
  flex: 1 1 40%;
}

.flex-45 {
  -webkit-flex: 1 1 45%;
  flex: 1 1 45%;
}

.layout-row>.flex-45 {
  -webkit-flex: 1 1 45%;
  flex: 1 1 45%;
}

.layout-row>.flex-45 {
  -webkit-flex: 1 1 45%;
  flex: 1 1 45%;
}

.flex-50 {
  -webkit-flex: 1 1 50%;
  flex: 1 1 50%;
}

.layout-row>.flex-50 {
  -webkit-flex: 1 1 50%;
  flex: 1 1 50%;
}

.layout-row>.flex-50 {
  -webkit-flex: 1 1 50%;
  flex: 1 1 50%;
}

.flex-55 {
  -webkit-flex: 1 1 55%;
  flex: 1 1 55%;
}

.layout-row>.flex-55 {
  -webkit-flex: 1 1 55%;
  flex: 1 1 55%;
}

.layout-row>.flex-55 {
  -webkit-flex: 1 1 55%;
  flex: 1 1 55%;
}

.flex-60 {
  -webkit-flex: 1 1 60%;
  flex: 1 1 60%;
}

.layout-row>.flex-60 {
  -webkit-flex: 1 1 60%;
  flex: 1 1 60%;
}

.layout-row>.flex-60 {
  -webkit-flex: 1 1 60%;
  flex: 1 1 60%;
}

.flex-65 {
  -webkit-flex: 1 1 65%;
  flex: 1 1 65%;
}

.layout-row>.flex-65 {
  -webkit-flex: 1 1 65%;
  flex: 1 1 65%;
}

.layout-row>.flex-65 {
  -webkit-flex: 1 1 65%;
  flex: 1 1 65%;
}

.flex-70 {
  -webkit-flex: 1 1 70%;
  flex: 1 1 70%;
}

.layout-row>.flex-70 {
  -webkit-flex: 1 1 70%;
  flex: 1 1 70%;
}

.layout-row>.flex-70 {
  -webkit-flex: 1 1 70%;
  flex: 1 1 70%;
}

.flex-75 {
  -webkit-flex: 1 1 75%;
  flex: 1 1 75%;
}

.layout-row>.flex-75 {
  -webkit-flex: 1 1 75%;
  flex: 1 1 75%;
}

.layout-row>.flex-75 {
  -webkit-flex: 1 1 75%;
  flex: 1 1 75%;
}

.flex-80 {
  -webkit-flex: 1 1 80%;
  flex: 1 1 80%;
}

.layout-row>.flex-80 {
  -webkit-flex: 1 1 80%;
  flex: 1 1 80%;
}

.layout-row>.flex-80 {
  -webkit-flex: 1 1 80%;
  flex: 1 1 80%;
}

.flex-85 {
  -webkit-flex: 1 1 85%;
  flex: 1 1 85%;
}

.layout-row>.flex-85 {
  -webkit-flex: 1 1 85%;
  flex: 1 1 85%;
}

.layout-row>.flex-85 {
  -webkit-flex: 1 1 85%;
  flex: 1 1 85%;
}

.flex-90 {
  -webkit-flex: 1 1 90%;
  flex: 1 1 90%;
}

.layout-row>.flex-90 {
  -webkit-flex: 1 1 90%;
  flex: 1 1 90%;
}

.layout-row>.flex-90 {
  -webkit-flex: 1 1 90%;
  flex: 1 1 90%;
}

.flex-95 {
  -webkit-flex: 1 1 95%;
  flex: 1 1 95%;
}

.layout-row>.flex-95 {
  -webkit-flex: 1 1 95%;
  flex: 1 1 95%;
}

.layout-row>.flex-95 {
  -webkit-flex: 1 1 95%;
  flex: 1 1 95%;
}

.flex-100 {
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row>.flex-100 {
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row>.flex-100 {
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row>.flex-33 {
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
}

.layout-row>.flex-66 {
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
}

.layout-column>.flex-33 {
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
}

.layout-column>.flex-66 {
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
}

.layout-row>.flex-33 {
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
}

.layout-row>.flex-66 {
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
}

.layout-row>.flex {
  min-width: 0;
}

.layout-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
}


/**
 * `hide-gt-sm show-gt-lg` should hide from 600px to 1200px
 * `show-md hide-gt-sm` should show from 0px to 960px and hide at >960px
 * `hide-gt-md show-gt-sm` should show everywhere (show overrides hide)`
 *
 *  hide means hide everywhere
 *  Sizes:
 *         $layout-breakpoint-xs:     600px !default;
 *         $layout-breakpoint-sm:     960px !default;
 *         $layout-breakpoint-md:     1280px !default;
 *         $layout-breakpoint-lg:     1920px !default;
 */

@media (max-width: 599px) {
  .flex-xs-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-row>.flex-xs-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-xs-row>.flex-xs-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .flex-xs-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-row>.flex-xs-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-xs-row>.flex-xs-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .flex-xs-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-row>.flex-xs-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-xs-row>.flex-xs-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .flex-xs-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-row>.flex-xs-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-xs-row>.flex-xs-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .flex-xs-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-row>.flex-xs-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-xs-row>.flex-xs-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .flex-xs-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-row>.flex-xs-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-xs-row>.flex-xs-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .flex-xs-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-row>.flex-xs-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-xs-row>.flex-xs-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .flex-xs-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-row>.flex-xs-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-xs-row>.flex-xs-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .flex-xs-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-row>.flex-xs-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-xs-row>.flex-xs-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .flex-xs-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-row>.flex-xs-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-xs-row>.flex-xs-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .flex-xs-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-row>.flex-xs-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-xs-row>.flex-xs-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .flex-xs-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-row>.flex-xs-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-xs-row>.flex-xs-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .flex-xs-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-row>.flex-xs-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-xs-row>.flex-xs-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .flex-xs-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-row>.flex-xs-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-xs-row>.flex-xs-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .flex-xs-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-row>.flex-xs-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-xs-row>.flex-xs-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .flex-xs-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-row>.flex-xs-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-xs-row>.flex-xs-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .flex-xs-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-row>.flex-xs-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-xs-row>.flex-xs-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .flex-xs-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-row>.flex-xs-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-xs-row>.flex-xs-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .flex-xs-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-row>.flex-xs-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-xs-row>.flex-xs-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .flex-xs-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-row>.flex-xs-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-xs-row>.flex-xs-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .flex-xs-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-xs-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-xs-row>.flex-xs-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-xs-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-row>.flex-xs-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-column>.flex-xs-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-column>.flex-xs-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-xs-row>.flex-xs-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-xs-row>.flex-xs-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-xs-row>.flex {
    min-width: 0;
  }
  .layout-xs-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 600px) {
  .flex-gt-xs-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-row>.flex-gt-xs-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-gt-xs-row>.flex-gt-xs-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .flex-gt-xs-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-row>.flex-gt-xs-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-gt-xs-row>.flex-gt-xs-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .flex-gt-xs-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-row>.flex-gt-xs-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-gt-xs-row>.flex-gt-xs-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .flex-gt-xs-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-row>.flex-gt-xs-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-gt-xs-row>.flex-gt-xs-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .flex-gt-xs-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-row>.flex-gt-xs-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-gt-xs-row>.flex-gt-xs-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .flex-gt-xs-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-row>.flex-gt-xs-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-gt-xs-row>.flex-gt-xs-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .flex-gt-xs-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-row>.flex-gt-xs-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-gt-xs-row>.flex-gt-xs-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .flex-gt-xs-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-row>.flex-gt-xs-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-gt-xs-row>.flex-gt-xs-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .flex-gt-xs-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-row>.flex-gt-xs-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-gt-xs-row>.flex-gt-xs-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .flex-gt-xs-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-row>.flex-gt-xs-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-gt-xs-row>.flex-gt-xs-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .flex-gt-xs-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-row>.flex-gt-xs-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-gt-xs-row>.flex-gt-xs-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .flex-gt-xs-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-row>.flex-gt-xs-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-gt-xs-row>.flex-gt-xs-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .flex-gt-xs-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-row>.flex-gt-xs-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-gt-xs-row>.flex-gt-xs-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .flex-gt-xs-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-row>.flex-gt-xs-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-gt-xs-row>.flex-gt-xs-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .flex-gt-xs-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-row>.flex-gt-xs-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-gt-xs-row>.flex-gt-xs-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .flex-gt-xs-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-row>.flex-gt-xs-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-gt-xs-row>.flex-gt-xs-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .flex-gt-xs-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-row>.flex-gt-xs-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-gt-xs-row>.flex-gt-xs-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .flex-gt-xs-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-row>.flex-gt-xs-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-gt-xs-row>.flex-gt-xs-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .flex-gt-xs-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-row>.flex-gt-xs-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-gt-xs-row>.flex-gt-xs-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .flex-gt-xs-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-row>.flex-gt-xs-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-gt-xs-row>.flex-gt-xs-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .flex-gt-xs-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-gt-xs-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-gt-xs-row>.flex-gt-xs-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-gt-xs-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-row>.flex-gt-xs-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-column>.flex-gt-xs-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-column>.flex-gt-xs-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-gt-xs-row>.flex-gt-xs-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-gt-xs-row>.flex-gt-xs-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-gt-xs-row>.flex {
    min-width: 0;
  }
  .layout-gt-xs-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .flex-sm-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-row>.flex-sm-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-sm-row>.flex-sm-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .flex-sm-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-row>.flex-sm-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-sm-row>.flex-sm-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .flex-sm-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-row>.flex-sm-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-sm-row>.flex-sm-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .flex-sm-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-row>.flex-sm-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-sm-row>.flex-sm-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .flex-sm-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-row>.flex-sm-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-sm-row>.flex-sm-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .flex-sm-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-row>.flex-sm-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-sm-row>.flex-sm-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .flex-sm-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-row>.flex-sm-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-sm-row>.flex-sm-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .flex-sm-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-row>.flex-sm-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-sm-row>.flex-sm-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .flex-sm-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-row>.flex-sm-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-sm-row>.flex-sm-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .flex-sm-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-row>.flex-sm-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-sm-row>.flex-sm-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .flex-sm-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-row>.flex-sm-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-sm-row>.flex-sm-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .flex-sm-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-row>.flex-sm-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-sm-row>.flex-sm-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .flex-sm-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-row>.flex-sm-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-sm-row>.flex-sm-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .flex-sm-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-row>.flex-sm-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-sm-row>.flex-sm-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .flex-sm-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-row>.flex-sm-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-sm-row>.flex-sm-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .flex-sm-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-row>.flex-sm-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-sm-row>.flex-sm-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .flex-sm-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-row>.flex-sm-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-sm-row>.flex-sm-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .flex-sm-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-row>.flex-sm-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-sm-row>.flex-sm-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .flex-sm-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-row>.flex-sm-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-sm-row>.flex-sm-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .flex-sm-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-row>.flex-sm-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-sm-row>.flex-sm-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .flex-sm-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-sm-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-sm-row>.flex-sm-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-sm-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-row>.flex-sm-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-column>.flex-sm-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-column>.flex-sm-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-sm-row>.flex-sm-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-sm-row>.flex-sm-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-sm-row>.flex {
    min-width: 0;
  }
  .layout-sm-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 960px) {
  .flex-gt-sm-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-row>.flex-gt-sm-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-gt-sm-row>.flex-gt-sm-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .flex-gt-sm-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-row>.flex-gt-sm-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-gt-sm-row>.flex-gt-sm-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .flex-gt-sm-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-row>.flex-gt-sm-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-gt-sm-row>.flex-gt-sm-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .flex-gt-sm-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-row>.flex-gt-sm-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-gt-sm-row>.flex-gt-sm-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .flex-gt-sm-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-row>.flex-gt-sm-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-gt-sm-row>.flex-gt-sm-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .flex-gt-sm-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-row>.flex-gt-sm-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-gt-sm-row>.flex-gt-sm-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .flex-gt-sm-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-row>.flex-gt-sm-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-gt-sm-row>.flex-gt-sm-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .flex-gt-sm-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-row>.flex-gt-sm-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-gt-sm-row>.flex-gt-sm-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .flex-gt-sm-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-row>.flex-gt-sm-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-gt-sm-row>.flex-gt-sm-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .flex-gt-sm-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-row>.flex-gt-sm-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-gt-sm-row>.flex-gt-sm-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .flex-gt-sm-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-row>.flex-gt-sm-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-gt-sm-row>.flex-gt-sm-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .flex-gt-sm-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-row>.flex-gt-sm-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-gt-sm-row>.flex-gt-sm-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .flex-gt-sm-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-row>.flex-gt-sm-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-gt-sm-row>.flex-gt-sm-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .flex-gt-sm-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-row>.flex-gt-sm-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-gt-sm-row>.flex-gt-sm-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .flex-gt-sm-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-row>.flex-gt-sm-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-gt-sm-row>.flex-gt-sm-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .flex-gt-sm-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-row>.flex-gt-sm-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-gt-sm-row>.flex-gt-sm-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .flex-gt-sm-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-row>.flex-gt-sm-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-gt-sm-row>.flex-gt-sm-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .flex-gt-sm-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-row>.flex-gt-sm-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-gt-sm-row>.flex-gt-sm-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .flex-gt-sm-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-row>.flex-gt-sm-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-gt-sm-row>.flex-gt-sm-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .flex-gt-sm-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-row>.flex-gt-sm-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-gt-sm-row>.flex-gt-sm-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .flex-gt-sm-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-gt-sm-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-gt-sm-row>.flex-gt-sm-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-gt-sm-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-row>.flex-gt-sm-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-column>.flex-gt-sm-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-column>.flex-gt-sm-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-gt-sm-row>.flex-gt-sm-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-gt-sm-row>.flex-gt-sm-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-gt-sm-row>.flex {
    min-width: 0;
  }
  .layout-gt-sm-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  .flex-md-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-row>.flex-md-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-md-row>.flex-md-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .flex-md-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-row>.flex-md-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-md-row>.flex-md-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .flex-md-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-row>.flex-md-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-md-row>.flex-md-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .flex-md-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-row>.flex-md-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-md-row>.flex-md-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .flex-md-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-row>.flex-md-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-md-row>.flex-md-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .flex-md-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-row>.flex-md-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-md-row>.flex-md-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .flex-md-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-row>.flex-md-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-md-row>.flex-md-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .flex-md-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-row>.flex-md-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-md-row>.flex-md-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .flex-md-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-row>.flex-md-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-md-row>.flex-md-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .flex-md-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-row>.flex-md-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-md-row>.flex-md-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .flex-md-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-row>.flex-md-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-md-row>.flex-md-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .flex-md-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-row>.flex-md-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-md-row>.flex-md-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .flex-md-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-row>.flex-md-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-md-row>.flex-md-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .flex-md-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-row>.flex-md-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-md-row>.flex-md-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .flex-md-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-row>.flex-md-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-md-row>.flex-md-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .flex-md-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-row>.flex-md-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-md-row>.flex-md-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .flex-md-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-row>.flex-md-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-md-row>.flex-md-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .flex-md-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-row>.flex-md-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-md-row>.flex-md-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .flex-md-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-row>.flex-md-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-md-row>.flex-md-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .flex-md-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-row>.flex-md-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-md-row>.flex-md-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .flex-md-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-md-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-md-row>.flex-md-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-md-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-row>.flex-md-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-column>.flex-md-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-column>.flex-md-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-md-row>.flex-md-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-md-row>.flex-md-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
}

@media (min-width: 1280px) {
  .flex-gt-md-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-row>.flex-gt-md-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-gt-md-row>.flex-gt-md-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .flex-gt-md-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-row>.flex-gt-md-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-gt-md-row>.flex-gt-md-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .flex-gt-md-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-row>.flex-gt-md-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-gt-md-row>.flex-gt-md-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .flex-gt-md-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-row>.flex-gt-md-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-gt-md-row>.flex-gt-md-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .flex-gt-md-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-row>.flex-gt-md-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-gt-md-row>.flex-gt-md-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .flex-gt-md-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-row>.flex-gt-md-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-gt-md-row>.flex-gt-md-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .flex-gt-md-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-row>.flex-gt-md-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-gt-md-row>.flex-gt-md-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .flex-gt-md-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-row>.flex-gt-md-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-gt-md-row>.flex-gt-md-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .flex-gt-md-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-row>.flex-gt-md-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-gt-md-row>.flex-gt-md-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .flex-gt-md-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-row>.flex-gt-md-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-gt-md-row>.flex-gt-md-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .flex-gt-md-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-row>.flex-gt-md-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-gt-md-row>.flex-gt-md-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .flex-gt-md-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-row>.flex-gt-md-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-gt-md-row>.flex-gt-md-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .flex-gt-md-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-row>.flex-gt-md-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-gt-md-row>.flex-gt-md-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .flex-gt-md-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-row>.flex-gt-md-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-gt-md-row>.flex-gt-md-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .flex-gt-md-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-row>.flex-gt-md-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-gt-md-row>.flex-gt-md-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .flex-gt-md-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-row>.flex-gt-md-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-gt-md-row>.flex-gt-md-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .flex-gt-md-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-row>.flex-gt-md-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-gt-md-row>.flex-gt-md-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .flex-gt-md-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-row>.flex-gt-md-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-gt-md-row>.flex-gt-md-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .flex-gt-md-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-row>.flex-gt-md-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-gt-md-row>.flex-gt-md-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .flex-gt-md-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-row>.flex-gt-md-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-gt-md-row>.flex-gt-md-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .flex-gt-md-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-gt-md-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-gt-md-row>.flex-gt-md-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-gt-md-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-row>.flex-gt-md-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-column>.flex-gt-md-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-column>.flex-gt-md-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-gt-md-row>.flex-gt-md-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-gt-md-row>.flex-gt-md-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-gt-md-row>.flex {
    min-width: 0;
  }
  .layout-gt-md-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}

.flex-lg-5 {
  -webkit-flex: 1 1 5%;
  flex: 1 1 5%;
}

.layout-row>.flex-lg-5 {
  -webkit-flex: 1 1 5%;
  flex: 1 1 5%;
}

.layout-lg-row>.flex-lg-5 {
  -webkit-flex: 1 1 5%;
  flex: 1 1 5%;
}

.flex-lg-10 {
  -webkit-flex: 1 1 10%;
  flex: 1 1 10%;
}

.layout-row>.flex-lg-10 {
  -webkit-flex: 1 1 10%;
  flex: 1 1 10%;
}

.layout-lg-row>.flex-lg-10 {
  -webkit-flex: 1 1 10%;
  flex: 1 1 10%;
}

.flex-lg-15 {
  -webkit-flex: 1 1 15%;
  flex: 1 1 15%;
}

.layout-row>.flex-lg-15 {
  -webkit-flex: 1 1 15%;
  flex: 1 1 15%;
}

.layout-lg-row>.flex-lg-15 {
  -webkit-flex: 1 1 15%;
  flex: 1 1 15%;
}

.flex-lg-20 {
  -webkit-flex: 1 1 20%;
  flex: 1 1 20%;
}

.layout-row>.flex-lg-20 {
  -webkit-flex: 1 1 20%;
  flex: 1 1 20%;
}

.layout-lg-row>.flex-lg-20 {
  -webkit-flex: 1 1 20%;
  flex: 1 1 20%;
}

.flex-lg-25 {
  -webkit-flex: 1 1 25%;
  flex: 1 1 25%;
}

.layout-row>.flex-lg-25 {
  -webkit-flex: 1 1 25%;
  flex: 1 1 25%;
}

.layout-lg-row>.flex-lg-25 {
  -webkit-flex: 1 1 25%;
  flex: 1 1 25%;
}

.flex-lg-30 {
  -webkit-flex: 1 1 30%;
  flex: 1 1 30%;
}

.layout-row>.flex-lg-30 {
  -webkit-flex: 1 1 30%;
  flex: 1 1 30%;
}

.layout-lg-row>.flex-lg-30 {
  -webkit-flex: 1 1 30%;
  flex: 1 1 30%;
}

.flex-lg-35 {
  -webkit-flex: 1 1 35%;
  flex: 1 1 35%;
}

.layout-row>.flex-lg-35 {
  -webkit-flex: 1 1 35%;
  flex: 1 1 35%;
}

.layout-lg-row>.flex-lg-35 {
  -webkit-flex: 1 1 35%;
  flex: 1 1 35%;
}

.flex-lg-40 {
  -webkit-flex: 1 1 40%;
  flex: 1 1 40%;
}

.layout-row>.flex-lg-40 {
  -webkit-flex: 1 1 40%;
  flex: 1 1 40%;
}

.layout-lg-row>.flex-lg-40 {
  -webkit-flex: 1 1 40%;
  flex: 1 1 40%;
}

.flex-lg-45 {
  -webkit-flex: 1 1 45%;
  flex: 1 1 45%;
}

.layout-row>.flex-lg-45 {
  -webkit-flex: 1 1 45%;
  flex: 1 1 45%;
}

.layout-lg-row>.flex-lg-45 {
  -webkit-flex: 1 1 45%;
  flex: 1 1 45%;
}

.flex-lg-50 {
  -webkit-flex: 1 1 50%;
  flex: 1 1 50%;
}

.layout-row>.flex-lg-50 {
  -webkit-flex: 1 1 50%;
  flex: 1 1 50%;
}

.layout-lg-row>.flex-lg-50 {
  -webkit-flex: 1 1 50%;
  flex: 1 1 50%;
}

.flex-lg-55 {
  -webkit-flex: 1 1 55%;
  flex: 1 1 55%;
}

.layout-row>.flex-lg-55 {
  -webkit-flex: 1 1 55%;
  flex: 1 1 55%;
}

.layout-lg-row>.flex-lg-55 {
  -webkit-flex: 1 1 55%;
  flex: 1 1 55%;
}

.flex-lg-60 {
  -webkit-flex: 1 1 60%;
  flex: 1 1 60%;
}

.layout-row>.flex-lg-60 {
  -webkit-flex: 1 1 60%;
  flex: 1 1 60%;
}

.layout-lg-row>.flex-lg-60 {
  -webkit-flex: 1 1 60%;
  flex: 1 1 60%;
}

.flex-lg-65 {
  -webkit-flex: 1 1 65%;
  flex: 1 1 65%;
}

.layout-row>.flex-lg-65 {
  -webkit-flex: 1 1 65%;
  flex: 1 1 65%;
}

.layout-lg-row>.flex-lg-65 {
  -webkit-flex: 1 1 65%;
  flex: 1 1 65%;
}

.flex-lg-70 {
  -webkit-flex: 1 1 70%;
  flex: 1 1 70%;
}

.layout-row>.flex-lg-70 {
  -webkit-flex: 1 1 70%;
  flex: 1 1 70%;
}

.layout-lg-row>.flex-lg-70 {
  -webkit-flex: 1 1 70%;
  flex: 1 1 70%;
}

.flex-lg-75 {
  -webkit-flex: 1 1 75%;
  flex: 1 1 75%;
}

.layout-row>.flex-lg-75 {
  -webkit-flex: 1 1 75%;
  flex: 1 1 75%;
}

.layout-lg-row>.flex-lg-75 {
  -webkit-flex: 1 1 75%;
  flex: 1 1 75%;
}

.flex-lg-80 {
  -webkit-flex: 1 1 80%;
  flex: 1 1 80%;
}

.layout-row>.flex-lg-80 {
  -webkit-flex: 1 1 80%;
  flex: 1 1 80%;
}

.layout-lg-row>.flex-lg-80 {
  -webkit-flex: 1 1 80%;
  flex: 1 1 80%;
}

.flex-lg-85 {
  -webkit-flex: 1 1 85%;
  flex: 1 1 85%;
}

.layout-row>.flex-lg-85 {
  -webkit-flex: 1 1 85%;
  flex: 1 1 85%;
}

.layout-lg-row>.flex-lg-85 {
  -webkit-flex: 1 1 85%;
  flex: 1 1 85%;
}

.flex-lg-90 {
  -webkit-flex: 1 1 90%;
  flex: 1 1 90%;
}

.layout-row>.flex-lg-90 {
  -webkit-flex: 1 1 90%;
  flex: 1 1 90%;
}

.layout-lg-row>.flex-lg-90 {
  -webkit-flex: 1 1 90%;
  flex: 1 1 90%;
}

.flex-lg-95 {
  -webkit-flex: 1 1 95%;
  flex: 1 1 95%;
}

.layout-row>.flex-lg-95 {
  -webkit-flex: 1 1 95%;
  flex: 1 1 95%;
}

.layout-lg-row>.flex-lg-95 {
  -webkit-flex: 1 1 95%;
  flex: 1 1 95%;
}

.flex-lg-100 {
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row>.flex-lg-100 {
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-lg-row>.flex-lg-100 {
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row>.flex-lg-33 {
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
}

.layout-row>.flex-lg-66 {
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
}

.layout-column>.flex-lg-33 {
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
}

.layout-column>.flex-lg-66 {
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
}

.layout-lg-row>.flex-lg-33 {
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
}

.layout-lg-row>.flex-lg-66 {
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
}

.layout-lg-row>.flex {
  min-width: 0;
}

.layout-lg-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
}

@media (min-width: 1920px) {
  .flex-gt-lg-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-row>.flex-gt-lg-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-gt-lg-row>.flex-gt-lg-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .flex-gt-lg-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-row>.flex-gt-lg-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-gt-lg-row>.flex-gt-lg-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .flex-gt-lg-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-row>.flex-gt-lg-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-gt-lg-row>.flex-gt-lg-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .flex-gt-lg-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-row>.flex-gt-lg-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-gt-lg-row>.flex-gt-lg-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .flex-gt-lg-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-row>.flex-gt-lg-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-gt-lg-row>.flex-gt-lg-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .flex-gt-lg-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-row>.flex-gt-lg-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-gt-lg-row>.flex-gt-lg-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .flex-gt-lg-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-row>.flex-gt-lg-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-gt-lg-row>.flex-gt-lg-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .flex-gt-lg-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-row>.flex-gt-lg-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-gt-lg-row>.flex-gt-lg-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .flex-gt-lg-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-row>.flex-gt-lg-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-gt-lg-row>.flex-gt-lg-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .flex-gt-lg-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-row>.flex-gt-lg-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-gt-lg-row>.flex-gt-lg-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .flex-gt-lg-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-row>.flex-gt-lg-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-gt-lg-row>.flex-gt-lg-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .flex-gt-lg-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-row>.flex-gt-lg-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-gt-lg-row>.flex-gt-lg-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .flex-gt-lg-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-row>.flex-gt-lg-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-gt-lg-row>.flex-gt-lg-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .flex-gt-lg-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-row>.flex-gt-lg-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-gt-lg-row>.flex-gt-lg-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .flex-gt-lg-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-row>.flex-gt-lg-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-gt-lg-row>.flex-gt-lg-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .flex-gt-lg-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-row>.flex-gt-lg-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-gt-lg-row>.flex-gt-lg-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .flex-gt-lg-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-row>.flex-gt-lg-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-gt-lg-row>.flex-gt-lg-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .flex-gt-lg-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-row>.flex-gt-lg-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-gt-lg-row>.flex-gt-lg-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .flex-gt-lg-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-row>.flex-gt-lg-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-gt-lg-row>.flex-gt-lg-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .flex-gt-lg-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-row>.flex-gt-lg-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-gt-lg-row>.flex-gt-lg-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .flex-gt-lg-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-gt-lg-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-gt-lg-row>.flex-gt-lg-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-gt-lg-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-row>.flex-gt-lg-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-column>.flex-gt-lg-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-column>.flex-gt-lg-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-gt-lg-row>.flex-gt-lg-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-gt-lg-row>.flex-gt-lg-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-gt-lg-row>.flex {
    min-width: 0;
  }
  .layout-gt-lg-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  .offset-xl-33,
  .flex-offset-xl-33 {
    margin-left: calc(100% / 3);
  }
  .offset-xl-66,
  .flex-offset-xl-66 {
    margin-left: calc(200% / 3);
  }
  [dir=rtl] .offset-xl-66,
  [dir=rtl] .flex-offset-xl-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }
  .flex-xl-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-row>.flex-xl-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-xl-row>.flex-xl-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .flex-xl-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-row>.flex-xl-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-xl-row>.flex-xl-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .flex-xl-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-row>.flex-xl-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-xl-row>.flex-xl-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .flex-xl-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-row>.flex-xl-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-xl-row>.flex-xl-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .flex-xl-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-row>.flex-xl-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-xl-row>.flex-xl-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .flex-xl-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-row>.flex-xl-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-xl-row>.flex-xl-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .flex-xl-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-row>.flex-xl-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-xl-row>.flex-xl-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .flex-xl-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-row>.flex-xl-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-xl-row>.flex-xl-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .flex-xl-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-row>.flex-xl-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-xl-row>.flex-xl-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .flex-xl-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-row>.flex-xl-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-xl-row>.flex-xl-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .flex-xl-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-row>.flex-xl-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-xl-row>.flex-xl-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .flex-xl-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-row>.flex-xl-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-xl-row>.flex-xl-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .flex-xl-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-row>.flex-xl-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-xl-row>.flex-xl-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .flex-xl-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-row>.flex-xl-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-xl-row>.flex-xl-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .flex-xl-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-row>.flex-xl-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-xl-row>.flex-xl-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .flex-xl-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-row>.flex-xl-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-xl-row>.flex-xl-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .flex-xl-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-row>.flex-xl-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-xl-row>.flex-xl-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .flex-xl-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-row>.flex-xl-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-xl-row>.flex-xl-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .flex-xl-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-row>.flex-xl-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-xl-row>.flex-xl-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .flex-xl-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-row>.flex-xl-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-xl-row>.flex-xl-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .flex-xl-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-xl-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-xl-row>.flex-xl-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-xl-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-row>.flex-xl-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-column>.flex-xl-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-column>.flex-xl-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-xl-row>.flex-xl-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-xl-row>.flex-xl-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
}

@media print {
  .flex-print-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-row>.flex-print-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-print-row>.flex-print-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .flex-print-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-row>.flex-print-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-print-row>.flex-print-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .flex-print-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-row>.flex-print-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-print-row>.flex-print-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .flex-print-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-row>.flex-print-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-print-row>.flex-print-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .flex-print-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-row>.flex-print-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-print-row>.flex-print-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .flex-print-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-row>.flex-print-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-print-row>.flex-print-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .flex-print-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-row>.flex-print-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-print-row>.flex-print-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .flex-print-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-row>.flex-print-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-print-row>.flex-print-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .flex-print-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-row>.flex-print-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-print-row>.flex-print-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .flex-print-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-row>.flex-print-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-print-row>.flex-print-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .flex-print-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-row>.flex-print-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-print-row>.flex-print-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .flex-print-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-row>.flex-print-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-print-row>.flex-print-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .flex-print-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-row>.flex-print-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-print-row>.flex-print-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .flex-print-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-row>.flex-print-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-print-row>.flex-print-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .flex-print-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-row>.flex-print-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-print-row>.flex-print-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .flex-print-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-row>.flex-print-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-print-row>.flex-print-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .flex-print-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-row>.flex-print-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-print-row>.flex-print-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .flex-print-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-row>.flex-print-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-print-row>.flex-print-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .flex-print-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-row>.flex-print-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-print-row>.flex-print-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .flex-print-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-row>.flex-print-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-print-row>.flex-print-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .flex-print-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-print-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-print-row>.flex-print-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-print-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-row>.flex-print-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-column>.flex-print-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-column>.flex-print-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-print-row>.flex-print-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-print-row>.flex-print-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-print-row>.flex {
    min-width: 0;
  }
  .layout-print-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  [hide-print]:not([show-print]):not([show]) {
    display: none;
  }
}


/*
* Since Layout API uses ng-cloak to hide the dom elements while layouts are adjusted
*
*/

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}


/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*
*/

@-moz-document url-prefix() {
  .layout-fill {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%;
  }
}


/*
 *  Apply Mixins to create Layout/Flexbox styles
 *
 */

.offset-33,
.flex-offset-33 {
  margin-left: calc(100% / 3);
}

.offset-66,
.flex-offset-66 {
  margin-left: calc(200% / 3);
}

[dir=rtl] .offset-66,
[dir=rtl] .flex-offset-66 {
  margin-left: auto;
  margin-right: calc(200% / 3);
}

.flex-0 {
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}

.layout-row>.flex-0 {
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}

.layout-row>.flex-0 {
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}

.flex-5 {
  -webkit-flex: 1 1 5%;
  flex: 1 1 5%;
}

.layout-row>.flex-5 {
  -webkit-flex: 1 1 5%;
  flex: 1 1 5%;
}

.layout-row>.flex-5 {
  -webkit-flex: 1 1 5%;
  flex: 1 1 5%;
}

.flex-10 {
  -webkit-flex: 1 1 10%;
  flex: 1 1 10%;
}

.layout-row>.flex-10 {
  -webkit-flex: 1 1 10%;
  flex: 1 1 10%;
}

.layout-row>.flex-10 {
  -webkit-flex: 1 1 10%;
  flex: 1 1 10%;
}

.flex-15 {
  -webkit-flex: 1 1 15%;
  flex: 1 1 15%;
}

.layout-row>.flex-15 {
  -webkit-flex: 1 1 15%;
  flex: 1 1 15%;
}

.layout-row>.flex-15 {
  -webkit-flex: 1 1 15%;
  flex: 1 1 15%;
}

.flex-20 {
  -webkit-flex: 1 1 20%;
  flex: 1 1 20%;
}

.layout-row>.flex-20 {
  -webkit-flex: 1 1 20%;
  flex: 1 1 20%;
}

.layout-row>.flex-20 {
  -webkit-flex: 1 1 20%;
  flex: 1 1 20%;
}

.flex-25 {
  -webkit-flex: 1 1 25%;
  flex: 1 1 25%;
}

.layout-row>.flex-25 {
  -webkit-flex: 1 1 25%;
  flex: 1 1 25%;
}

.layout-row>.flex-25 {
  -webkit-flex: 1 1 25%;
  flex: 1 1 25%;
}

.flex-30 {
  -webkit-flex: 1 1 30%;
  flex: 1 1 30%;
}

.layout-row>.flex-30 {
  -webkit-flex: 1 1 30%;
  flex: 1 1 30%;
}

.layout-row>.flex-30 {
  -webkit-flex: 1 1 30%;
  flex: 1 1 30%;
}

.flex-35 {
  -webkit-flex: 1 1 35%;
  flex: 1 1 35%;
}

.layout-row>.flex-35 {
  -webkit-flex: 1 1 35%;
  flex: 1 1 35%;
}

.layout-row>.flex-35 {
  -webkit-flex: 1 1 35%;
  flex: 1 1 35%;
}

.flex-40 {
  -webkit-flex: 1 1 40%;
  flex: 1 1 40%;
}

.layout-row>.flex-40 {
  -webkit-flex: 1 1 40%;
  flex: 1 1 40%;
}

.layout-row>.flex-40 {
  -webkit-flex: 1 1 40%;
  flex: 1 1 40%;
}

.flex-45 {
  -webkit-flex: 1 1 45%;
  flex: 1 1 45%;
}

.layout-row>.flex-45 {
  -webkit-flex: 1 1 45%;
  flex: 1 1 45%;
}

.layout-row>.flex-45 {
  -webkit-flex: 1 1 45%;
  flex: 1 1 45%;
}

.flex-50 {
  -webkit-flex: 1 1 50%;
  flex: 1 1 50%;
}

.layout-row>.flex-50 {
  -webkit-flex: 1 1 50%;
  flex: 1 1 50%;
}

.layout-row>.flex-50 {
  -webkit-flex: 1 1 50%;
  flex: 1 1 50%;
}

.flex-55 {
  -webkit-flex: 1 1 55%;
  flex: 1 1 55%;
}

.layout-row>.flex-55 {
  -webkit-flex: 1 1 55%;
  flex: 1 1 55%;
}

.layout-row>.flex-55 {
  -webkit-flex: 1 1 55%;
  flex: 1 1 55%;
}

.flex-60 {
  -webkit-flex: 1 1 60%;
  flex: 1 1 60%;
}

.layout-row>.flex-60 {
  -webkit-flex: 1 1 60%;
  flex: 1 1 60%;
}

.layout-row>.flex-60 {
  -webkit-flex: 1 1 60%;
  flex: 1 1 60%;
}

.flex-65 {
  -webkit-flex: 1 1 65%;
  flex: 1 1 65%;
}

.layout-row>.flex-65 {
  -webkit-flex: 1 1 65%;
  flex: 1 1 65%;
}

.layout-row>.flex-65 {
  -webkit-flex: 1 1 65%;
  flex: 1 1 65%;
}

.flex-70 {
  -webkit-flex: 1 1 70%;
  flex: 1 1 70%;
}

.layout-row>.flex-70 {
  -webkit-flex: 1 1 70%;
  flex: 1 1 70%;
}

.layout-row>.flex-70 {
  -webkit-flex: 1 1 70%;
  flex: 1 1 70%;
}

.flex-75 {
  -webkit-flex: 1 1 75%;
  flex: 1 1 75%;
}

.layout-row>.flex-75 {
  -webkit-flex: 1 1 75%;
  flex: 1 1 75%;
}

.layout-row>.flex-75 {
  -webkit-flex: 1 1 75%;
  flex: 1 1 75%;
}

.flex-80 {
  -webkit-flex: 1 1 80%;
  flex: 1 1 80%;
}

.layout-row>.flex-80 {
  -webkit-flex: 1 1 80%;
  flex: 1 1 80%;
}

.layout-row>.flex-80 {
  -webkit-flex: 1 1 80%;
  flex: 1 1 80%;
}

.flex-85 {
  -webkit-flex: 1 1 85%;
  flex: 1 1 85%;
}

.layout-row>.flex-85 {
  -webkit-flex: 1 1 85%;
  flex: 1 1 85%;
}

.layout-row>.flex-85 {
  -webkit-flex: 1 1 85%;
  flex: 1 1 85%;
}

.flex-90 {
  -webkit-flex: 1 1 90%;
  flex: 1 1 90%;
}

.layout-row>.flex-90 {
  -webkit-flex: 1 1 90%;
  flex: 1 1 90%;
}

.layout-row>.flex-90 {
  -webkit-flex: 1 1 90%;
  flex: 1 1 90%;
}

.flex-95 {
  -webkit-flex: 1 1 95%;
  flex: 1 1 95%;
}

.layout-row>.flex-95 {
  -webkit-flex: 1 1 95%;
  flex: 1 1 95%;
}

.layout-row>.flex-95 {
  -webkit-flex: 1 1 95%;
  flex: 1 1 95%;
}

.flex-100 {
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row>.flex-100 {
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row>.flex-100 {
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row>.flex-33 {
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
}

.layout-row>.flex-66 {
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
}

.layout-column>.flex-33 {
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
}

.layout-column>.flex-66 {
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
}

.layout-row>.flex-33 {
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
}

.layout-row>.flex-66 {
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
}

.layout-row>.flex {
  min-width: 0;
}

.layout-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
}


/**
 * `hide-gt-sm show-gt-lg` should hide from 600px to 1200px
 * `show-md hide-gt-sm` should show from 0px to 960px and hide at >960px
 * `hide-gt-md show-gt-sm` should show everywhere (show overrides hide)`
 *
 *  hide means hide everywhere
 *  Sizes:
 *         $layout-breakpoint-xs:     600px !default;
 *         $layout-breakpoint-sm:     960px !default;
 *         $layout-breakpoint-md:     1280px !default;
 *         $layout-breakpoint-lg:     1920px !default;
 */

@media (max-width: 599px) {
  .flex-xs-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-row>.flex-xs-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-xs-row>.flex-xs-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .flex-xs-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-row>.flex-xs-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-xs-row>.flex-xs-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .flex-xs-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-row>.flex-xs-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-xs-row>.flex-xs-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .flex-xs-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-row>.flex-xs-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-xs-row>.flex-xs-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .flex-xs-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-row>.flex-xs-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-xs-row>.flex-xs-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .flex-xs-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-row>.flex-xs-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-xs-row>.flex-xs-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .flex-xs-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-row>.flex-xs-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-xs-row>.flex-xs-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .flex-xs-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-row>.flex-xs-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-xs-row>.flex-xs-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .flex-xs-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-row>.flex-xs-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-xs-row>.flex-xs-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .flex-xs-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-row>.flex-xs-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-xs-row>.flex-xs-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .flex-xs-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-row>.flex-xs-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-xs-row>.flex-xs-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .flex-xs-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-row>.flex-xs-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-xs-row>.flex-xs-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .flex-xs-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-row>.flex-xs-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-xs-row>.flex-xs-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .flex-xs-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-row>.flex-xs-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-xs-row>.flex-xs-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .flex-xs-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-row>.flex-xs-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-xs-row>.flex-xs-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .flex-xs-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-row>.flex-xs-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-xs-row>.flex-xs-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .flex-xs-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-row>.flex-xs-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-xs-row>.flex-xs-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .flex-xs-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-row>.flex-xs-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-xs-row>.flex-xs-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .flex-xs-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-row>.flex-xs-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-xs-row>.flex-xs-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .flex-xs-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-row>.flex-xs-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-xs-row>.flex-xs-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .flex-xs-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-xs-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-xs-row>.flex-xs-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-xs-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-row>.flex-xs-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-column>.flex-xs-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-column>.flex-xs-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-xs-row>.flex-xs-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-xs-row>.flex-xs-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-xs-row>.flex {
    min-width: 0;
  }
  .layout-xs-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 600px) {
  .flex-gt-xs-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-row>.flex-gt-xs-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-gt-xs-row>.flex-gt-xs-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .flex-gt-xs-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-row>.flex-gt-xs-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-gt-xs-row>.flex-gt-xs-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .flex-gt-xs-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-row>.flex-gt-xs-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-gt-xs-row>.flex-gt-xs-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .flex-gt-xs-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-row>.flex-gt-xs-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-gt-xs-row>.flex-gt-xs-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .flex-gt-xs-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-row>.flex-gt-xs-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-gt-xs-row>.flex-gt-xs-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .flex-gt-xs-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-row>.flex-gt-xs-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-gt-xs-row>.flex-gt-xs-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .flex-gt-xs-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-row>.flex-gt-xs-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-gt-xs-row>.flex-gt-xs-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .flex-gt-xs-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-row>.flex-gt-xs-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-gt-xs-row>.flex-gt-xs-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .flex-gt-xs-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-row>.flex-gt-xs-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-gt-xs-row>.flex-gt-xs-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .flex-gt-xs-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-row>.flex-gt-xs-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-gt-xs-row>.flex-gt-xs-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .flex-gt-xs-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-row>.flex-gt-xs-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-gt-xs-row>.flex-gt-xs-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .flex-gt-xs-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-row>.flex-gt-xs-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-gt-xs-row>.flex-gt-xs-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .flex-gt-xs-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-row>.flex-gt-xs-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-gt-xs-row>.flex-gt-xs-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .flex-gt-xs-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-row>.flex-gt-xs-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-gt-xs-row>.flex-gt-xs-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .flex-gt-xs-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-row>.flex-gt-xs-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-gt-xs-row>.flex-gt-xs-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .flex-gt-xs-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-row>.flex-gt-xs-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-gt-xs-row>.flex-gt-xs-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .flex-gt-xs-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-row>.flex-gt-xs-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-gt-xs-row>.flex-gt-xs-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .flex-gt-xs-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-row>.flex-gt-xs-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-gt-xs-row>.flex-gt-xs-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .flex-gt-xs-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-row>.flex-gt-xs-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-gt-xs-row>.flex-gt-xs-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .flex-gt-xs-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-row>.flex-gt-xs-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-gt-xs-row>.flex-gt-xs-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .flex-gt-xs-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-gt-xs-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-gt-xs-row>.flex-gt-xs-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-gt-xs-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-row>.flex-gt-xs-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-column>.flex-gt-xs-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-column>.flex-gt-xs-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-gt-xs-row>.flex-gt-xs-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-gt-xs-row>.flex-gt-xs-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-gt-xs-row>.flex {
    min-width: 0;
  }
  .layout-gt-xs-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .flex-sm-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-row>.flex-sm-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-sm-row>.flex-sm-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .flex-sm-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-row>.flex-sm-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-sm-row>.flex-sm-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .flex-sm-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-row>.flex-sm-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-sm-row>.flex-sm-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .flex-sm-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-row>.flex-sm-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-sm-row>.flex-sm-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .flex-sm-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-row>.flex-sm-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-sm-row>.flex-sm-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .flex-sm-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-row>.flex-sm-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-sm-row>.flex-sm-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .flex-sm-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-row>.flex-sm-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-sm-row>.flex-sm-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .flex-sm-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-row>.flex-sm-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-sm-row>.flex-sm-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .flex-sm-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-row>.flex-sm-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-sm-row>.flex-sm-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .flex-sm-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-row>.flex-sm-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-sm-row>.flex-sm-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .flex-sm-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-row>.flex-sm-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-sm-row>.flex-sm-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .flex-sm-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-row>.flex-sm-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-sm-row>.flex-sm-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .flex-sm-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-row>.flex-sm-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-sm-row>.flex-sm-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .flex-sm-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-row>.flex-sm-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-sm-row>.flex-sm-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .flex-sm-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-row>.flex-sm-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-sm-row>.flex-sm-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .flex-sm-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-row>.flex-sm-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-sm-row>.flex-sm-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .flex-sm-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-row>.flex-sm-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-sm-row>.flex-sm-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .flex-sm-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-row>.flex-sm-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-sm-row>.flex-sm-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .flex-sm-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-row>.flex-sm-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-sm-row>.flex-sm-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .flex-sm-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-row>.flex-sm-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-sm-row>.flex-sm-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .flex-sm-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-sm-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-sm-row>.flex-sm-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-sm-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-row>.flex-sm-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-column>.flex-sm-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-column>.flex-sm-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-sm-row>.flex-sm-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-sm-row>.flex-sm-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-sm-row>.flex {
    min-width: 0;
  }
  .layout-sm-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 960px) {
  .flex-gt-sm-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-row>.flex-gt-sm-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-gt-sm-row>.flex-gt-sm-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .flex-gt-sm-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-row>.flex-gt-sm-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-gt-sm-row>.flex-gt-sm-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .flex-gt-sm-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-row>.flex-gt-sm-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-gt-sm-row>.flex-gt-sm-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .flex-gt-sm-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-row>.flex-gt-sm-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-gt-sm-row>.flex-gt-sm-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .flex-gt-sm-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-row>.flex-gt-sm-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-gt-sm-row>.flex-gt-sm-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .flex-gt-sm-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-row>.flex-gt-sm-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-gt-sm-row>.flex-gt-sm-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .flex-gt-sm-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-row>.flex-gt-sm-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-gt-sm-row>.flex-gt-sm-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .flex-gt-sm-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-row>.flex-gt-sm-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-gt-sm-row>.flex-gt-sm-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .flex-gt-sm-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-row>.flex-gt-sm-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-gt-sm-row>.flex-gt-sm-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .flex-gt-sm-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-row>.flex-gt-sm-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-gt-sm-row>.flex-gt-sm-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .flex-gt-sm-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-row>.flex-gt-sm-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-gt-sm-row>.flex-gt-sm-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .flex-gt-sm-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-row>.flex-gt-sm-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-gt-sm-row>.flex-gt-sm-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .flex-gt-sm-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-row>.flex-gt-sm-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-gt-sm-row>.flex-gt-sm-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .flex-gt-sm-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-row>.flex-gt-sm-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-gt-sm-row>.flex-gt-sm-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .flex-gt-sm-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-row>.flex-gt-sm-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-gt-sm-row>.flex-gt-sm-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .flex-gt-sm-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-row>.flex-gt-sm-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-gt-sm-row>.flex-gt-sm-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .flex-gt-sm-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-row>.flex-gt-sm-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-gt-sm-row>.flex-gt-sm-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .flex-gt-sm-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-row>.flex-gt-sm-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-gt-sm-row>.flex-gt-sm-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .flex-gt-sm-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-row>.flex-gt-sm-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-gt-sm-row>.flex-gt-sm-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .flex-gt-sm-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-row>.flex-gt-sm-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-gt-sm-row>.flex-gt-sm-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .flex-gt-sm-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-gt-sm-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-gt-sm-row>.flex-gt-sm-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-gt-sm-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-row>.flex-gt-sm-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-column>.flex-gt-sm-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-column>.flex-gt-sm-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-gt-sm-row>.flex-gt-sm-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-gt-sm-row>.flex-gt-sm-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  .flex-md-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-row>.flex-md-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-md-row>.flex-md-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .flex-md-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-row>.flex-md-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-md-row>.flex-md-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .flex-md-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-row>.flex-md-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-md-row>.flex-md-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .flex-md-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-row>.flex-md-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-md-row>.flex-md-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .flex-md-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-row>.flex-md-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-md-row>.flex-md-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .flex-md-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-row>.flex-md-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-md-row>.flex-md-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .flex-md-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-row>.flex-md-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-md-row>.flex-md-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .flex-md-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-row>.flex-md-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-md-row>.flex-md-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .flex-md-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-row>.flex-md-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-md-row>.flex-md-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .flex-md-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-row>.flex-md-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-md-row>.flex-md-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .flex-md-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-row>.flex-md-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-md-row>.flex-md-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .flex-md-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-row>.flex-md-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-md-row>.flex-md-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .flex-md-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-row>.flex-md-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-md-row>.flex-md-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .flex-md-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-row>.flex-md-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-md-row>.flex-md-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .flex-md-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-row>.flex-md-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-md-row>.flex-md-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .flex-md-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-row>.flex-md-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-md-row>.flex-md-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .flex-md-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-row>.flex-md-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-md-row>.flex-md-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .flex-md-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-row>.flex-md-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-md-row>.flex-md-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .flex-md-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-row>.flex-md-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-md-row>.flex-md-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .flex-md-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-row>.flex-md-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-md-row>.flex-md-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .flex-md-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-md-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-md-row>.flex-md-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-md-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-row>.flex-md-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-column>.flex-md-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-column>.flex-md-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-md-row>.flex-md-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-md-row>.flex-md-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
}

@media (min-width: 1280px) {
  .flex-gt-md-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-row>.flex-gt-md-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-gt-md-row>.flex-gt-md-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .flex-gt-md-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-row>.flex-gt-md-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-gt-md-row>.flex-gt-md-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .flex-gt-md-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-row>.flex-gt-md-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-gt-md-row>.flex-gt-md-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .flex-gt-md-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-row>.flex-gt-md-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-gt-md-row>.flex-gt-md-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .flex-gt-md-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-row>.flex-gt-md-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-gt-md-row>.flex-gt-md-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .flex-gt-md-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-row>.flex-gt-md-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-gt-md-row>.flex-gt-md-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .flex-gt-md-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-row>.flex-gt-md-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-gt-md-row>.flex-gt-md-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .flex-gt-md-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-row>.flex-gt-md-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-gt-md-row>.flex-gt-md-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .flex-gt-md-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-row>.flex-gt-md-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-gt-md-row>.flex-gt-md-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .flex-gt-md-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-row>.flex-gt-md-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-gt-md-row>.flex-gt-md-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .flex-gt-md-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-row>.flex-gt-md-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-gt-md-row>.flex-gt-md-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .flex-gt-md-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-row>.flex-gt-md-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-gt-md-row>.flex-gt-md-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .flex-gt-md-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-row>.flex-gt-md-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-gt-md-row>.flex-gt-md-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .flex-gt-md-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-row>.flex-gt-md-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-gt-md-row>.flex-gt-md-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .flex-gt-md-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-row>.flex-gt-md-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-gt-md-row>.flex-gt-md-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .flex-gt-md-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-row>.flex-gt-md-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-gt-md-row>.flex-gt-md-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .flex-gt-md-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-row>.flex-gt-md-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-gt-md-row>.flex-gt-md-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .flex-gt-md-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-row>.flex-gt-md-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-gt-md-row>.flex-gt-md-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .flex-gt-md-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-row>.flex-gt-md-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-gt-md-row>.flex-gt-md-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .flex-gt-md-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-row>.flex-gt-md-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-gt-md-row>.flex-gt-md-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .flex-gt-md-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-gt-md-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-gt-md-row>.flex-gt-md-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-gt-md-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-row>.flex-gt-md-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-column>.flex-gt-md-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-column>.flex-gt-md-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-gt-md-row>.flex-gt-md-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-gt-md-row>.flex-gt-md-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-gt-md-row>.flex {
    min-width: 0;
  }
  .layout-gt-md-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .flex-lg-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-row>.flex-lg-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-lg-row>.flex-lg-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .flex-lg-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-row>.flex-lg-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-lg-row>.flex-lg-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .flex-lg-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-row>.flex-lg-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-lg-row>.flex-lg-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .flex-lg-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-row>.flex-lg-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-lg-row>.flex-lg-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .flex-lg-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-row>.flex-lg-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-lg-row>.flex-lg-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .flex-lg-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-row>.flex-lg-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-lg-row>.flex-lg-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .flex-lg-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-row>.flex-lg-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-lg-row>.flex-lg-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .flex-lg-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-row>.flex-lg-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-lg-row>.flex-lg-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .flex-lg-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-row>.flex-lg-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-lg-row>.flex-lg-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .flex-lg-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-row>.flex-lg-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-lg-row>.flex-lg-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .flex-lg-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-row>.flex-lg-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-lg-row>.flex-lg-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .flex-lg-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-row>.flex-lg-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-lg-row>.flex-lg-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .flex-lg-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-row>.flex-lg-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-lg-row>.flex-lg-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .flex-lg-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-row>.flex-lg-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-lg-row>.flex-lg-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .flex-lg-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-row>.flex-lg-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-lg-row>.flex-lg-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .flex-lg-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-row>.flex-lg-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-lg-row>.flex-lg-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .flex-lg-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-row>.flex-lg-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-lg-row>.flex-lg-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .flex-lg-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-row>.flex-lg-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-lg-row>.flex-lg-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .flex-lg-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-row>.flex-lg-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-lg-row>.flex-lg-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .flex-lg-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-row>.flex-lg-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-lg-row>.flex-lg-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .flex-lg-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-lg-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-lg-row>.flex-lg-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-lg-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-row>.flex-lg-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-column>.flex-lg-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-column>.flex-lg-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-lg-row>.flex-lg-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-lg-row>.flex-lg-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-lg-row>.flex {
    min-width: 0;
  }
  .layout-lg-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 1920px) {
  .flex-gt-lg-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-row>.flex-gt-lg-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-gt-lg-row>.flex-gt-lg-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .flex-gt-lg-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-row>.flex-gt-lg-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-gt-lg-row>.flex-gt-lg-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .flex-gt-lg-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-row>.flex-gt-lg-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-gt-lg-row>.flex-gt-lg-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .flex-gt-lg-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-row>.flex-gt-lg-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-gt-lg-row>.flex-gt-lg-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .flex-gt-lg-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-row>.flex-gt-lg-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-gt-lg-row>.flex-gt-lg-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .flex-gt-lg-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-row>.flex-gt-lg-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-gt-lg-row>.flex-gt-lg-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .flex-gt-lg-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-row>.flex-gt-lg-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-gt-lg-row>.flex-gt-lg-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .flex-gt-lg-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-row>.flex-gt-lg-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-gt-lg-row>.flex-gt-lg-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .flex-gt-lg-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-row>.flex-gt-lg-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-gt-lg-row>.flex-gt-lg-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .flex-gt-lg-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-row>.flex-gt-lg-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-gt-lg-row>.flex-gt-lg-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .flex-gt-lg-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-row>.flex-gt-lg-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-gt-lg-row>.flex-gt-lg-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .flex-gt-lg-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-row>.flex-gt-lg-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-gt-lg-row>.flex-gt-lg-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .flex-gt-lg-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-row>.flex-gt-lg-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-gt-lg-row>.flex-gt-lg-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .flex-gt-lg-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-row>.flex-gt-lg-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-gt-lg-row>.flex-gt-lg-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .flex-gt-lg-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-row>.flex-gt-lg-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-gt-lg-row>.flex-gt-lg-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .flex-gt-lg-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-row>.flex-gt-lg-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-gt-lg-row>.flex-gt-lg-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .flex-gt-lg-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-row>.flex-gt-lg-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-gt-lg-row>.flex-gt-lg-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .flex-gt-lg-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-row>.flex-gt-lg-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-gt-lg-row>.flex-gt-lg-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .flex-gt-lg-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-row>.flex-gt-lg-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-gt-lg-row>.flex-gt-lg-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .flex-gt-lg-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-row>.flex-gt-lg-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-gt-lg-row>.flex-gt-lg-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .flex-gt-lg-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-gt-lg-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-gt-lg-row>.flex-gt-lg-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-gt-lg-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-row>.flex-gt-lg-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-column>.flex-gt-lg-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-column>.flex-gt-lg-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-gt-lg-row>.flex-gt-lg-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-gt-lg-row>.flex-gt-lg-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-gt-lg-row>.flex {
    min-width: 0;
  }
  .layout-gt-lg-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  .offset-xl-33,
  .flex-offset-xl-33 {
    margin-left: calc(100% / 3);
  }
  .offset-xl-66,
  .flex-offset-xl-66 {
    margin-left: calc(200% / 3);
  }
  [dir=rtl] .offset-xl-66,
  [dir=rtl] .flex-offset-xl-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }
  .flex-xl-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-row>.flex-xl-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .layout-xl-row>.flex-xl-0 {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .flex-xl-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-row>.flex-xl-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .layout-xl-row>.flex-xl-5 {
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
  }
  .flex-xl-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-row>.flex-xl-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .layout-xl-row>.flex-xl-10 {
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
  }
  .flex-xl-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-row>.flex-xl-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .layout-xl-row>.flex-xl-15 {
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
  }
  .flex-xl-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-row>.flex-xl-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .layout-xl-row>.flex-xl-20 {
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
  }
  .flex-xl-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-row>.flex-xl-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .layout-xl-row>.flex-xl-25 {
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
  }
  .flex-xl-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-row>.flex-xl-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .layout-xl-row>.flex-xl-30 {
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
  }
  .flex-xl-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-row>.flex-xl-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .layout-xl-row>.flex-xl-35 {
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
  }
  .flex-xl-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-row>.flex-xl-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .layout-xl-row>.flex-xl-40 {
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
  }
  .flex-xl-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-row>.flex-xl-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .layout-xl-row>.flex-xl-45 {
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
  }
  .flex-xl-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-row>.flex-xl-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .layout-xl-row>.flex-xl-50 {
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
  }
  .flex-xl-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-row>.flex-xl-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .layout-xl-row>.flex-xl-55 {
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
  }
  .flex-xl-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-row>.flex-xl-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .layout-xl-row>.flex-xl-60 {
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
  }
  .flex-xl-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-row>.flex-xl-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .layout-xl-row>.flex-xl-65 {
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
  }
  .flex-xl-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-row>.flex-xl-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .layout-xl-row>.flex-xl-70 {
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
  }
  .flex-xl-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-row>.flex-xl-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .layout-xl-row>.flex-xl-75 {
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
  }
  .flex-xl-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-row>.flex-xl-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .layout-xl-row>.flex-xl-80 {
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
  }
  .flex-xl-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-row>.flex-xl-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .layout-xl-row>.flex-xl-85 {
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
  }
  .flex-xl-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-row>.flex-xl-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .layout-xl-row>.flex-xl-90 {
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
  }
  .flex-xl-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-row>.flex-xl-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .layout-xl-row>.flex-xl-95 {
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
  }
  .flex-xl-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-xl-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-xl-row>.flex-xl-100 {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .layout-row>.flex-xl-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-row>.flex-xl-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-column>.flex-xl-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-column>.flex-xl-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
  .layout-xl-row>.flex-xl-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
  .layout-xl-row>.flex-xl-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }
}

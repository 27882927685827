.accommodation-form-page {
  .accommodation-person-added {
    opacity: 0.5;

    .md-secondary {
      display: none;
    }
  }

  .accommodation-person-less-age td:not(.md-icon-cell) {
    opacity: 0.6;
  }

  .accommodation-title {
    padding-right: 0;

    .md-subhead {
      padding-left: 33px;
      display: block;
    }

    .status-icon {
      fill: $success;
      margin-right: 7px;
      vertical-align: middle;
    }

    .accommodation-room-full .status-icon {
      fill: $danger;
    }
  }
}
.public-findRetailer-page {
  .logo-event {
    text-align: center;

    img {
      width: 100%;
      max-height: 250px;
    }
  }

  .company-name {
    color: rgb(11, 134, 200);
    font-size: 16px;
    font-weight: bold;
  }

  .status-participant {
    font-size: 14px;
    font-weight: bold;
  }

  p {
    margin: 5px 0px;
  }

  .md-cell:not(.v-middle) {
    vertical-align: middle !important;
  }

  .cell-center {
    width: 80%;
  }

  .pull-right {
    float: right;
  }

  .activity-dark-blue {
    background: darken($accent, 10%);
    color: $white !important;

    md-icon {
      fill: $white;
    }

    .attach-button {
      color: $white;
    }
  }

  .activity-grey td {
    background: #e0e0e0;
    color: #000;
  }

  .attach-button {
    color: #757575;
    font-size: 18px;
    width: 60px;

    md-icon {
      display: inline-block;
    }
  }

  img.activity-logo {
    max-height: 120px;
    max-width: 150px;
  }

  img.activity-logo-small {
    max-height: 60px;
    max-width: 60px;
  }

  .speaker-box {
    margin: 10px 10px 0 0;
    width: 150px;
    background: white;
    color: black;
    border-radius: 2px;

    >div {
      height: 100px;
      display: table;
      margin: auto;

      >div {
        display: table-cell;
        vertical-align: middle;
      }

      img {
        max-width: 150px;
        max-height: 100px;
      }
    }
  }

  .label-wrap {
    word-wrap: break-word;
  }

  .layout-column {
    align-items: flex-start;
  }

  .speaker-rows {
    justify-content: flex-start;
  }

  .activity-grey td {
    color: #000 !important;
    background: #FFF !important;
  }

  md-icon {
    fill: #000 !important;
  }

  .onlyprint {
    display: block;
    border: none !important;
  }

  .format {
    max-width: 100%;
    min-width: 100%;
    text-align: center;
    display: block;

    >div {
      display: block;
    }

    p {
      max-width: 100%;
      min-width: 100%;
      font-size: 20px;
      display: block;
    }
  }

  .noSpeakerPhoto {
    text-align: center;
    font-size: 15px;
    margin-left: 20px;
    margin-top: 50px;
  }

}

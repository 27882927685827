.p-l-md {
  padding-left: 20px;
}

.p-t-md {
  padding-top: 20px;
}

.p-b-sm {
  padding-bottom: 10px;
}

.p-xs {
  padding: 8px;
}

.p-sm {
  padding: 12px;
}

.p-lg {
  padding: 50px;
}

.p-none {
  padding: 0 !important;
}

.text-right {
  text-align: right !important;
}

.m-auto {
  margin: auto;
}

.m-bottom {
  margin-bottom: 20px;
}

.m-r-xs {
  margin-right: 10px;
}

.m-input {
  margin-top: 20px !important;
}

.w-full {
  width: 100%;
}

.m-none {
  margin: 0 !important;
}

.m-t-none {
  margin-top: 0;
}

.m-t-md {
  margin-top: 20px;
}

.m-b-none {
  margin-bottom: 0;
}

.m-b-xs {
  margin-bottom: 5px;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.nowrap {
  white-space: nowrap;
}

.v-middle {
  vertical-align: middle !important;
}

label.md-label,
p.md-label {
  font-size: 12px;
  margin-bottom: 5px;
  opacity: 0.7;
}

.pre {
  white-space: pre-line;
}

.v-top td {
  vertical-align: top;
}

.text-success {
  color: $success !important;
}

.text-danger {
  color: $danger !important;
}

.table-fixed {
  table-layout: fixed;
}

.lh-normal {
  line-height: normal !important;
}

.margin-top {
  margin-top: -15px;
}

.bold {
  font-weight: bold;
}

.scroll-y {
  overflow-y: auto;
  overflow-x: hidden;
}

.bg-white {
  background-color: white;
}

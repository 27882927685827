.activity-statistics-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: white;
  overflow: auto;
  zoom: 1.2;

  md-content {
    background: white;
  }

  .primary-color {
    color: $accent;
  }

  .margin-top {
    margin-top: 12px;
  }

  .margin-top-bar {
    margin-top: 20px;
  }

  md-progress-linear div {
    height: 15px !important;
  }

  md-progress-linear>div {
    background: $border-color-darker !important;
  }

  .logo-white {
    max-height: 130px;
    max-width: 130px;
  }

  .logo-event {
    margin-top: 140px;
    width: 100%;
    height: 100%;
  }
}

md-content {
  color: inherit;
  background: $background-color;
}

md-sidenav {
  cursor: default !important;
  header {
    background: $accent;
    color: $white;
    padding: 0 16px;
  }
}

md-toolbar.md-table-toolbar.md-default-theme:not(.md-menu-toolbar).md-default .md-button,
md-toolbar.md-table-toolbar:not(.md-menu-toolbar).md-default .md-button {
  color: rgba(0, 0, 0, .54);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
}

.md-button:not(.md-icon-button):not(.md-icon-button-text)>md-icon {
  margin-top: 5px;
  margin-right: 5px;
  float: left;
}

.md-button.md-icon-button-text {
  min-width: 0;
  md-icon {
    margin-top: -5px;
    +span {
      margin-left: 5px;
    }
  }
}

.text-image {
  text-transform: uppercase;
  font-size: 14px;
  color: rgba(0, 0, 0, .54);
  display: inline;
}

md-icon,
ng-md-icon {
  fill: currentColor;
  &[size="32"] {
    width: 32px;
    height: 32px;
  }
  svg {
    vertical-align: middle;
    fill: inherit;
  }
}

.img-banner {
  min-width: 57vw;
}

.md-fab {
  fill: $white;
  ng-md-icon svg {
    vertical-align: sub;
  }
}

md-fab-speed-dial.md-fab-bottom-right {
  position: fixed;
}

md-input-container {
  margin: 0;
  margin: 15px 0;
  .md-errors-spacer {
    min-height: 0;
  }
}

body p {
  &.md-input-has-error {
    margin: 0 0 30px;
    font-size: 0.9rem;
    color: #ff6363;
    align-self: center;
  }
}

md-datepicker {
  margin-top: 11px !important;
  margin-bottom: 7px;
  margin-right: 10px !important;
  padding-right: 0 !important;
  padding-left: 10px !important;
  display: block;
  .md-datepicker-input-container {
    margin-left: 0;
    width: calc(100% - 40px);
  }
  .md-datepicker-button {
    padding-left: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

md-datepicker,
md-input-container {
  +[ng-messages],
  [ng-messages] {
    min-height: 19px;
    &.ng-hide {
      display: block !important;
      visibility: hidden;
    }
  }
}

.md-dialog-container md-dialog {
  max-height: 95vh;
  md-dialog-actions {
    border-top: 1px solid $border-color;
    padding: 10px 20px;
    .md-button:first-child {
      margin-left: 0;
    }
    .error-message {
      margin: 0;
      padding: 0 8px;
    }
  }
}

@include media-max-breakpoints(sm) {
  .md-dialog-fullscreen {
    md-dialog-content {
      min-height: calc(100vh - 120px);
    }
  }
  .md-dialog-container md-dialog {
    .md-dialog-content {
      padding: 15px;
    }
    md-dialog-actions {
      padding: 10px 15px;
    }
  }
}

.ng-submitted,
md-datepicker {
  &.ng-invalid:not(.ng-untouched),
  md-datepicker.ng-invalid {
    .md-datepicker-input-container {
      border-color: $danger;
      border-width: 2px;
    } // md-icon {
    //   fill: rgba($danger, 0.8);
    // }
    &+[ng-messages] {
      padding-left: 50px;
      color: $danger;
      font-size: 12px;
      margin-top: -7px;
    }
  }
}

#loading {
  position: absolute;
  z-index: 10000;
  width: 100%;
  height: 100%;
  cursor: wait;
  background: rgba(0, 0, 0, 0.7);
  md-progress-circular {
    margin: auto;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.abf-search-toolbar {
  padding-top: 0.5rem;
  md-input-container {
    padding-right: 0;
    width: 100%;
    input {
      color: #fff;
    }
  }
}

timeline {
  display: block;
}

timeline-panel {
  position: relative;
  background: #fafafa;
  padding: 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
  border-radius: 2px;
  border: none;
  $color: lighten($accent, 15%);
  &:after,
  &:before {
    border-left-color: $color;
    border-right-color: $color;
    top: 12px;
  }
  .timeline-panel {
    overflow: hidden;
  }
  h3,
  h4,
  p {
    padding: 0;
    margin: 0;
    font-weight: normal;
  }
  &.selected {
    &:after,
    &:before {
      border-left-color: #2a3897;
      border-right-color: #2a3897;
    }
    .timeline-heading {
      background: #2a3897;
    }
  }
  .timeline-heading {
    padding: 1rem;
    background: $color;
    position: relative;
    &:before {
      top: 0;
      -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 -1px 8px rgba(0, 0, 0, 0.28);
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 -1px 8px rgba(0, 0, 0, 0.28);
      content: "";
      height: 100%;
      left: 0;
      right: 0;
      position: absolute;
      will-change: top, left;
    }
    h3,
    h4 {
      max-width: 75%;
      color: #FFF;
    }
    md-checkbox {
      width: 100%;
      margin-bottom: 0;
      .md-label {
        display: block;
        h3,
        h4 {
          max-width: 100%;
          user-select: none;
        }
      }
      &.md-default-theme:not(.md-checked) .md-icon,
      &:not(.md-checked) .md-icon {
        border-color: $white;
      }
    }
  }
  .timeline-body {
    padding: 1rem;
    md-icon {
      margin-right: 0.5rem;
    }
    strong {
      color: #444;
    }
    p:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }
  }
  md-menu {
    position: absolute;
    top: 10px;
    right: 10px;
    md-icon {
      color: white;
    }
  }
}

.mdp-datepicker .mdp-datepicker-date-wrapper span {
  position: relative;
  z-index: 1;
}

@include media-max-breakpoints(sm) {
  output-page-toolbar .md-toolbar-tools {
    padding-left: 0;
  }
}

.cards-list {
  margin: 0 -8px;
}

md-card md-card-title .md-button {
  margin: -10px 0 0 0 !important;
}

.card-image {
  position: relative;
  overflow: hidden;
  height: 200px;
  background-size: cover;
  background-position: center;
  md-card-title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    color: $white;
    padding: 16px;
  }
  md-card-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: $white;
    margin: 0;
    md-icon {
      fill: $white;
    }
  }
}

select-event-component {
  >div {
    display: block;
    padding: 16px;
    transition: 0.3s;
    background: $accent;
    color: $white;
  }
  .md-caption {
    margin: 0 0 5px 0;
  }
  md-select-value {
    border-bottom-color: $white !important;
  }
  .md-select-value {
    color: $white !important;
  }
  .md-select-icon {
    color: $white;
  }
  label {
    color: $white !important;
  }
  .event-name {
    max-width: calc(100% - 30px);
    display: inline-block;
    margin: 0;
  }
  &:hover {
    background: darken($accent, 5%);
  }
  md-icon {
    margin-top: -3px;
    float: right;
    color: $white;
  }
}

md-tabs.no-header>md-tabs-wrapper {
  display: none;
}

.main-tabs-header {
  md-toolbar.main-toolbar:before {
    display: none;
  }
  .header-tabs-content>md-tabs-wrapper {
    display: none;
  }
}

output-header-tabs {
  md-toolbar {
    min-height: 0;
    &:before {
      box-shadow: 0 3px 4px rgba(0, 0, 0, 0.28)
    }
  }
  md-toolbar>md-tabs>md-tabs-wrapper>md-tabs-canvas>md-pagination-wrapper>md-tab-item {
    color: $white !important;
  }

  md-tabs md-ink-bar {
    background: $white !important;
  }
}

.information {
  font-size: 14px;
  margin: 8px 8px 20px 8px;
  background: darken($accent, 10%);
  padding: 10px;
  border-radius: 2px;
  color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
  display: table;
  width: calc(100% - 16px);
  box-sizing: border-box;

  md-icon {
    margin-right: 5px;
    color: inherit;
  }

  p {
    float: right;
    width: calc(100% - 40px);
    margin: 5px 0;
  }
}

.md-subhead md-icon {
  margin-right: 10px;
  margin-top: -2px;
  float: left;
}

.empty-message {
  text-align: center;
  opacity: 0.6;
  font-style: italic;
}

md-table-container+.empty-message {
  border-top: 1px solid $border-color-darker;
  margin-top: 0;
  padding: 10px 0;
}


.multiline li {
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal;
  line-height: 20px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

md-select.md-table-select>.md-select-value {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  margin-top: 5px;
}

payment-status-icon {
  display: block;
}

.md-button.md-icon-button.md-icon-button-small-text {
  border-radius: 0;
  width: auto;
  padding: 0 5px;

  small {
    display: block;
    margin-top: -8px;
    font-size: 10px;
  }
}

md-toast {
  z-index: 10001;
}

.participant-color-picker,
.md-color-picker-input {
  color: #000 !important;
}

$white: rgba(255, 255, 255, 0.87);
$border-radius: 2px;
$success: #8cc63f;
$danger: #c62828;
$info: #55acee;
$primary: #0eb1ba;
$warning: #efdb13;
$background-color: #f2f2f2;
$color: #616161;
$border-radius-base: 3px;
$accent: #0b86c8;



/*Sidemenu*/

$side-background: #37474F;
$side-color: white;
$side-header: #263238;



/*Border*/

$border-color-darker: #bfbebe;
$border-color: #F3F3F3;

md-toolbar {
  &.md-table-toolbar {
    &:before {
      box-shadow: none;
      pointer-events: none;
    }
    form>input {
      width: 100%;
      margin: 0;
      border: none;
      padding: 5px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

table.md-table {
  tr {
    &.filter {
      background-color: #fcfcfc;
      md-input-container {
        width: 100%;
        margin: 0;
        input {
          height: 28px;
        }
      }
    }
  }
  td {
    &.md-icon-cell {
      text-align: right;
      vertical-align: middle;
      button.md-icon-button {
        padding: 0;
        margin: 0 -6px;
      }
    }
  }
}

table.md-table td.md-checkbox-cell md-checkbox {
  margin-top: 3px;
}

table.md-table td.md-cell:nth-child(n+2):nth-last-child(n+2),
table.md-table th.md-column:nth-child(n+2):nth-last-child(n+2) {
  padding: 8px 4px !important;
}

table.md-table td.md-cell:first-child,
table.md-table th.md-column:first-child {
  padding: 8px 4px 8px 8px !important;
}

table.md-table td.md-cell:last-child,
table.md-table th.md-column:last-child {
  padding: 0 8px 0 4px !important;
}

@include media-min-breakpoints(xs) {
  table.md-table td.md-cell:nth-child(n+2):nth-last-child(n+2),
  table.md-table th.md-column:nth-child(n+2):nth-last-child(n+2) {
    padding: 16px 8px !important;
  }
  table.md-table td.md-cell:first-child,
  table.md-table th.md-column:first-child {
    padding: 16px 8px 16px 16px !important;
  }
  table.md-table td.md-cell:not(.md-icon-cell):last-child,
  table.md-table th.md-column:not(.md-icon-cell):last-child {
    padding: 16px 16px 16px 8px !important;
  }
}

 .public-certificate-page {
   .logo-event {
     text-align: center;
     img {
       width: 100%;
       max-height: 250px;
     }
   }
   .section-header {
     position: relative;
     padding: 0;
     margin: 0 10px 0 -10px;
     overflow: hidden;
     display: inline-block;
     p {
       background: $accent;
       color: white;
       text-transform: uppercase;
       display: inline-block;
       min-width: 160px;
       padding: 16px 90px 16px 16px;
       margin-top: 10px;
     }
     &:before {
       content: '';
       position: absolute;
       left: 0px;
       top: 0;
       border: 10px solid darken($accent, 10%);
       border: 5px solid #086698;
       border-top-color: transparent;
       border-left-color: transparent;
     }
     &:after {
       content: '';
       position: absolute;
       right: 0;
       top: 10px;
       bottom: 0;
       border: 150px solid white;
       border-top-color: transparent;
       border-left-color: transparent;
     }
   }
   .options-list {
     max-width: 900px;
     margin: auto;
     .option-description {
       min-width: 150px;
       max-width: 150px;
       text-align: center;
     }
     .options .option {
       text-align: center;
       margin-bottom: 10px;
       min-height: 40px;
     }
     md-radio-button {
       width: 20px;
       margin-top: 5px;
       display: inline-block;
     }
     .error-message {
       margin: 0;
       padding: 15px 0 0 0;
       min-height: 40px;
       box-sizing: border-box;
     }
   }
 }

.public-dashboard-page {
  .md-cell:not(.v-middle) {
    vertical-align: middle !important;
  }

  .cell-center {
    width: 80%;
  }

  .pull-right {
    float: right;
  }

  .logo-event {
    text-align: center;

    img {
      width: 100%;
      max-height: 250px;
    }
  }

  .activity-dark-blue {
    background: darken($accent, 10%);
    color: $white !important;

    md-icon {
      fill: $white;
    }

    .attach-button {
      color: $white;
    }
  }

  .activity-grey td {
    background: #e0e0e0;
    color: #000;
  }

  .attach-button {
    color: #757575;
    font-size: 18px;
    width: 60px;

    md-icon {
      display: inline-block;
    }

    p {
      display: inline;
    }
  }

  img.activity-logo {
    max-height: 120px;
    max-width: 150px;
  }

  img.activity-logo-small {
    max-height: 120px;
    max-width: 250px;
    margin: 10px 0;
    margin-left: 15px;
  }

  .speaker-box {
    margin: 10px 10px 0 0;
    width: 150px;
    background: white;
    color: black;
    border-radius: 2px;

    >div {
      height: 100px;
      display: table;
      margin: auto;

      >div {
        display: table-cell;
        vertical-align: middle;
      }

      img {
        max-width: 150px;
        max-height: 100px;
      }
    }

    p {
      padding: 0 10px;
    }
  }

  .label-wrap {
    word-wrap: break-word;
  }

  .layout-column {
    align-items: flex-start;
  }

  @media print {
    @page {
      margin: 0;
    }

    .speaker-rows {
      justify-content: flex-start;
    }

    .imgs-print {
      max-width: 200px;
      float: right;
      margin-top: -20px;
      margin-left: 30px;

      img+img {
        margin-left: 15px;
      }
    }

    md-card {
      border: none !important;
      box-shadow: none !important;
      padding: 0 !important;
      margin: 0;
    }

    .activity-grey td {
      color: #000 !important;
      background: #FFF !important;
    }

    md-icon {
      fill: #000 !important;
    }

    .onlyprint {
      display: block;
      border: none !important;
    }

    .format {
      max-width: 100%;
      min-width: 100%;
      text-align: center;
      display: block;

      >div {
        display: block;
      }

      p {
        max-width: 100%;
        min-width: 100%;
        font-size: 20px;
        display: block;
      }
    }
  }

  .noSpeakerPhoto {
    text-align: center;
    font-size: 15px;
    margin-left: 20px;
    margin-top: 50px;
  }

  pre {
    font-family: sans-serif;
    font-size: 12px;
    line-height: 1.5;
  }

  .titleTerm {
    font-size: 16px;
    font-weight: bold;
    color: rgb(11, 134, 200);
  }
}

.admin-dashboard {
  box-shadow: none !important;
  margin: 0;
  .chart {
    width: 400px;
    max-width: 300px;
    text-align: center;
    height: 380px;
    min-height: 380px;
    margin-bottom: 20px;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 10px;
  }
  @media print {
    md-card {
      border: none !important;
      box-shadow: none !important;
      padding: 0 !important;
      margin: 0;
    }
    p {
      max-width: 100%;
      min-width: 100%;
      font-size: 22px;
      display: block;
      text-align: center;
    }
  }
  .pullright {
    margin-left: 30px;
  }
}

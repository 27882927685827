@media print {
  md-card-content {
    page-break-after: always;
  }
  .onlyprint {
    table {
      width: 100%;
    }
  }
  .margin-top-title {
    margin-top: -20px;
  }
  .margin-top-table {
    margin-top: 40px;
  }
}
